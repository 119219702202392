import React from 'react';
import PageTitle from './PageTitle';

import * as styles from './BooksPage.module.scss';

const COMPANY_CEO_BOOKS = [
  {
    title: 'Super Pumped: The Battle for Uber',
    link: 'https://amzn.to/3ayC2tV',
    author: 'Mike Isaac',
  },
  {
    title: "An Ugly Truth: Inside Facebook's Battle for Domination",
    link: 'https://amzn.to/3PmYDZw',
    author: 'Sheera Frenkel and Cecilia Kang',
  },
  {
    title: 'Amazon Unbound: Jeff Bezos and the Invention of a Global Empire',
    link: 'https://amzn.to/3yXJmZD',
    author: 'Brad Stone',
  },
  {
    title:
      'The Founders: The Story of Paypal and the Entrepreneurs Who Shaped Silicon Valley',
    link: 'https://amzn.to/3c8JEUk',
    author: 'Jimmy Soni',
  },
  {
    title: 'Elon Musk: Tesla, SpaceX, and the Quest for a Fantastic Future',
    link: 'https://amzn.to/3OV8NR4',
    author: 'Ashlee Vance',
  },
  {
    title:
      'Billion Dollar Loser: The Epic Rise and Spectacular Fall of Adam Neumann and WeWork',
    link: 'https://amzn.to/3uHn9wu',
    author: 'Reeves Wiedeman',
  },
  {
    title:
      'The Cult of We: WeWork, Adam Neumann, and the Great Startup Delusion',
    link: 'https://amzn.to/3c4oRBu',
    author: 'Eliot Brown and Maureen Farrell',
  },
  {
    title: 'In the Plex: How Google Thinks, Works, and Shapes Our Lives',
    link: 'https://amzn.to/3yDnRMu',
    author: 'Steven Levy',
  },
  {
    title:
      'Creativity, Inc.: Overcoming the Unseen Forces That Stand in the Way of True Inspiration',
    link: 'https://amzn.to/3auelTC',
    author: 'Ed Catmull and Amy Wallace',
  },
  {
    title: 'No Filter: The Inside Story of Instagram',
    link: 'https://amzn.to/3PmGZFd',
    author: 'Sarah Frier',
  },
  {
    title: 'Bad Blood: Secrets and Lies in a Silicon Valley Startup',
    link: 'https://amzn.to/3AO5C9P',
    author: 'John Carreyrou',
  },
  {
    title: 'Uncanny Valley',
    link: 'https://amzn.to/3bUz43i',
    author: 'Anna Wiener',
  },
  {
    title: 'Steve Jobs',
    link: 'https://amzn.to/3RoTXE4',
    author: 'Walter Isaacson',
  },
  {
    title: 'No Rules Rules: Netflix and the Culture of Reinvention',
    link: 'https://amzn.to/3c3lx9M',
    author: 'Reed Hastings and Erin Meyer',
  },
  {
    title: 'Hatching Twitter',
    link: 'https://amzn.to/3Pn1HED',
    author: 'Nick Bilton',
  },

  {
    title: 'The Soul of A New Machine',
    link: 'https://amzn.to/3o0hAoR',
    author: 'Tracy Kidder',
  },
  {
    title:
      'The Innovators: How a Group of Hackers, Geniuses, and Geeks Created the Digital Revolution',
    link: 'https://amzn.to/3ccCkYc',
    author: 'Walter Isaacson',
  },
  {
    title:
      'That Will Never Work: The Birth of Netflix and the Amazing Life of an Idea',
    link: 'https://amzn.to/3c8bRec',
    author: '',
  },
  {
    title: 'The Everything Store: Jeff Bezos and the Age of Amazon',
    link: 'https://amzn.to/3c7LmW3',
    author: 'Brad Stone',
  },
  {
    title: 'The New New Thing: A Silicon Valley Story',
    link: 'https://amzn.to/3nSocFN',
    author: 'Michael Lewis',
  },
];

const BUILD_BIZ_BOOKS = [
  {
    title:
      'The Hard Thing About Hard Things: Building a Business When There Are No Easy Answers',
    link: 'https://amzn.to/3Rr1fHk',
    author: 'Ben Horowitz',
  },
  {
    title: 'What You Do Is Who You Are: How to Create Your Business Culture',
    link: 'https://amzn.to/3OUEQk9',
    author: 'Ben Horowitz',
  },
  {
    title: 'The Cold Start Problem: How to Start and Scale Network Effects',
    link: 'https://amzn.to/3yyZfnV',
    author: 'Andrew Chen',
  },
  {
    title: 'Build: An Unorthodox Guide to Making Things Worth Making',
    link: 'https://amzn.to/3aD1T3X',
    author: 'Tony Fadell',
  },
  {
    title:
      'Blitzscaling: The Lightning-Fast Path to Building Massively Valuable Companies',
    link: 'https://amzn.to/3PkvWMx',
    author: 'Reid Hoffman and Chris Yeh',
  },
  {
    title:
      "Masters of Scale: Surprising Truths from the World's Most Successful Entrepreneurs",
    link: 'https://amzn.to/3ywUJWY',
    author: 'Reid Hoffman',
  },
  {
    title: 'Zero to One: Notes on Startups, or How to Build the Future',
    link: 'https://amzn.to/3IwRYcQ',
    author: 'Peter Thiel with Blake Masters',
  },
  {
    title: 'High Growth Handbook: Scaling Startups From 10 to 10,000 People',
    link: 'https://amzn.to/3yWuMSe',
    author: 'Elad Gil',
  },
  {
    title: 'The Lean Startup',
    link: 'https://amzn.to/3nWw8WB',
    author: 'Eric Ries',
  },
  {
    title:
      "Trillion Dollar Coach: The Leadership Playbook of Silicon Valley's Bill Campbell",
    link: 'https://amzn.to/3P5rTEm',
    author: 'Eric Schmidt and Jonathan Rosenberg and Alan Eagle',
  },
  {
    title:
      'Crossing the Chasm: Marketing and Selling Disruptive Products to Mainstream Customers',
    link: 'https://amzn.to/3AFwpFb',
    author: 'Geoffrey A. Moore',
  },

  {
    title:
      'Measure What Matters: How Google, Bono, and the Gates Foundation Rock the World with OKRs',
    link: 'https://amzn.to/3Ruyaen',
    author: 'John Doerr',
  },

  {
    title:
      "The Innovator's Dilemma: When New Technologies Cause Great Firms to Fail",
    link: 'https://amzn.to/3P7VrkM',
    author: 'Clayton M. Christensen',
  },
  {
    title:
      "The Innovator's Solution: Creating and Sustaining Successful Growth",
    link: 'https://amzn.to/3c7coge',
    author: 'Clayton M. Christensen',
  },
  {
    title: 'The Making of a Manager: What to Do When Everyone Looks to You',
    link: 'https://amzn.to/3AFxNYp',
    author: 'Julie Zhuo',
  },
  {
    title: 'High Output Management',
    link: 'https://amzn.to/3OUEl9L',
    author: 'Andrew S. Grove',
  },
];

const DESIGN_BOOKS = [
  {
    title: 'The Design of Everyday Things',
    link: 'https://amzn.to/3uJ0Mql',
    author: 'Don Norman',
  },
  {
    title: 'Hooked: How to Build Habit-Forming Products',
    link: 'https://amzn.to/3yYV3zd',
    author: 'Nir Eyal with Ryan Hoover',
  },
  {
    title: "Don't Make Me Think: A Common Sense Approach to Web Usability",
    link: 'https://amzn.to/3c3nrqW',
    author: 'Steve Krug',
  },
  {
    title:
      'User Friendly- How the Hidden Rules of Design Are Changing the Way We Live, Work, and Play',
    link: 'https://amzn.to/3uDShwU',
    author: 'Cliff Kuang with Robert Fabricant',
  },
  {
    title: 'The Best Interface Is No Interface',
    link: 'https://amzn.to/3uG8GAX',
    author: 'Krishna Golden',
  },
  {
    title: 'About Face: The Essentials of Interaction Design',
    link: 'https://amzn.to/3uHgulH',
    author: 'Alan Cooper, Robert Reimann, David Cronin, Christopher Noessel',
  },
  {
    title:
      'Sprint: How to Solve Big Problems and Test New Ideas in Just Five Days',
    link: 'https://amzn.to/3ItYNMp',
    author: 'Jake Knapp with John Zeratsky and Braden Kowitz',
  },
  {
    title:
      'The 99% Invisible City: A Field Guide to the Hidden World of Everyday Design',
    link: 'https://amzn.to/3PmG33o',
    author: 'Roman Mars and Kurt Kohlstedt',
  },
  {
    title:
      'Change by Design, Revised and Updated: How Design Thinking Transforms Organizations and Inspires Innovation',
    link: 'https://amzn.to/3uKnvlR',
    author: 'Tim Brown',
  },
];
// pm books
// {
//     title: 'Inspired: How to Create Tech Products Customers Love',
//     link: 'https://amzn.to/3uI4vnY',
//     author: 'Marty Cagan',
//   },

const BooksPage = () => {
  return (
    <>
      <div className={styles.Container}>
        <PageTitle title="Tech Books" path="/tech-books" />
        <div>
          Curated book list to help you dive deeper into various topics in tech
        </div>
        <div className={styles.SmallText}>
          ** these are affiliate links so buying books from here helps support
          readss
        </div>
        <h2 className={styles.Header}>Learn about companies and CEOs</h2>
        <ul>
          {COMPANY_CEO_BOOKS.map(({title, link, author}) => {
            return (
              <li>
                <a target="_blank" href={link} rel="noreferrer">
                  {title} by {author}
                </a>
              </li>
            );
          })}
        </ul>
        <h2 className={styles.Header}>Learn about building businesses</h2>
        <ul>
          {BUILD_BIZ_BOOKS.map(({title, link, author}) => {
            return (
              <li>
                <a target="_blank" href={link} rel="noreferrer">
                  {title} by {author}
                </a>
              </li>
            );
          })}
        </ul>
        <h2 className={styles.Header}>Learn about design</h2>
        <ul>
          {DESIGN_BOOKS.map(({title, link, author}) => {
            return (
              <li>
                <a target="_blank" href={link} rel="noreferrer">
                  {title} by {author}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default BooksPage;
