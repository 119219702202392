import React from 'react';

import Page from '../components/Page';
import BooksPage from '../components/BooksPage';

const TechBooks = () => {
  return (
    <Page
      title="Books"
      path="/tech-books"
      description="Curated list of books about tech CEOs, companies, building businesses, and design."
      breadcrumb="/tech-books"
    >
      <BooksPage />
    </Page>
  );
};

export default TechBooks;
